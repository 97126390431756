.btn-close:hover {
    background-color: #FFC78B;
}
.btn-close {
    background-color: #4A8F9F;
    cursor: pointer;
    transition: all 0.3s;
}
.bg-gradient-white {
  background-color: white;
  * {
    color: #77838F;
  }
}