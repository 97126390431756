//
// breadcrumb.scss
// Extended from Bootstrap
//

.breadcrumb-item {
    font-size: $breadcrumb-font-size;

    + .breadcrumb-item {

        &::before {
            content: "/";
        }
    }
}


// Horizontal scroll

.breadcrumb-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;

    .breadcrumb-item {
        white-space: nowrap;
    }

    // Hide scrollbar

    &::-webkit-scrollbar {
        display: none;
    }
}
