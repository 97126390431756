//
// position.scss
// Extended from Bootstrap
//

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-xl {
    @include media-breakpoint-up(xl) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.place-center {
    place-items: center;
}

.place-content-center {
    place-content: center;
}

.place-flex-center {
    place-items: center;
    place-content: center;
}

.center-y {
    transform: translate(0%, 50%);
}

.position-center-y {
    top: 50%;
    transform: translate(0%, -50%);
}

.center-n-y {
    @include media-breakpoint-up(lg) {
        transform: translate(0%, -50%);
    }
}

.center-md-y {
    @include media-breakpoint-up(md) {
        transform: translate(0%, 50%);
    }
}

a {
    .hover-visible {
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }

    &:hover {
        .hover-visible {
            opacity: 1;
            visibility: visible;
        }
    }
}

.z-index-0 {
    z-index: 0;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-md {
    z-index: 9;
}

.z-index-lg {
    z-index: 999;
}

.z-index-xl {
    z-index: 9999;
}

.mb-n-1 {
    margin-bottom: -1px;
}

.mt-n-1 {
    margin-top: -1px;
}


// Responsive positions

@each $breakpoint, $value in $grid-breakpoints {
  @if ($breakpoint !='xs') {
    @include media-breakpoint-up(#{$breakpoint}) {
      @each $position in $positions {
        .position-#{$breakpoint}-#{$position} {
          position: $position !important;
        }
      }
    }
  }
}

.flex-shrink-all * {
    flex-shrink: 0;
}

// Cursor parallax

.cs-parallax {
    position: relative;
}
.cs-parallax-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > img {
        display: block;
        width: 100%;
    }
}

.header-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;

    @include media-breakpoint-up(md) {
        height: 110px;
        display: flex;
        align-items: center;
    }
}


.vertical-scroll {
    overflow-x: hidden;
    overflow-y: auto;
}
