@font-face {
  font-family: 'skolaIcon';
  src:  url('fonts/skolaIcon.eot?w6jnat');
  src:  url('fonts/skolaIcon.eot?w6jnat#iefix') format('embedded-opentype'),
    url('fonts/skolaIcon.ttf?w6jnat') format('truetype'),
    url('fonts/skolaIcon.woff?w6jnat') format('woff'),
    url('fonts/skolaIcon.svg?w6jnat#skolaIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sk-"], [class*=" sk-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'skolaIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sk-arrow-left:before {
  content: "\e900";
}
.sk-arrow-right:before {
  content: "\e901";
}
.sk-book:before {
  content: "\e902";
}
.sk-calendar-2:before {
  content: "\e903";
}
.sk-calendar:before {
  content: "\e904";
}
.sk-chat:before {
  content: "\e905";
}
.sk-close:before {
  content: "\e906";
}
.sk-courses:before {
  content: "\e907";
}
.sk-dashboard:before {
  content: "\e908";
}
.sk-dot-three:before {
  content: "\e909";
}
.sk-eye:before {
  content: "\e90a";
}
.sk-file:before {
  content: "\e90b";
}
.sk-flag:before {
  content: "\e90c";
}
.sk-geometry:before {
  content: "\e90d";
}
.sk-hat:before {
  content: "\e90e";
}
.sk-heart:before {
  content: "\e90f";
}
.sk-home:before {
  content: "\e910";
}
.sk-icon-user-2:before {
  content: "\e911";
}
.sk-link:before {
  content: "\e912";
}
.sk-logout:before {
  content: "\e913";
}
.sk-notification:before {
  content: "\e914";
}
.sk-order:before {
  content: "\e915";
}
.sk-pen:before {
  content: "\e916";
}
.sk-quizze:before {
  content: "\e917";
}
.sk-search:before {
  content: "\e918";
}
.sk-setting-2:before {
  content: "\e919";
}
.sk-setting:before {
  content: "\e91a";
}
.sk-site:before {
  content: "\e91b";
}
.sk-sort-up-down:before {
  content: "\e91c";
}
.sk-teamwork:before {
  content: "\e91d";
}
.sk-trash:before {
  content: "\e91e";
}
.sk-user:before {
  content: "\e91f";
}
.sk-zoom-in:before {
  content: "\e920";
}
