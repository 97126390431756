/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import 'node_modules/bootstrap/scss/functions';

// Theme functions
@import 'theme/functions';

// Custom variables
@import 'user-variables';

// Theme variables
@import 'theme/variables';

// Bootstrap core
@import 'node_modules/bootstrap/scss/bootstrap';

// Theme core
@import 'theme/theme';

// Custom core
@import 'user';

// Skola Icons
@import '../fonts/skola-icon/style.css';


// Properties from sb-admin theme
@import '_sb-admin.scss';


// Custom Properties
@import 'custom.scss';