//
// type.scss
// Extended from Bootstrap
//

//
// Font sizing
//

.font-size-10 {
    font-size: .625rem !important; //10px
}

.font-size-15 {
    font-size: .937rem !important; //15px
}

.font-size-xs {
    font-size: .75rem !important; //12px
}

.font-size-base {
    font-size: $font-size-base; //16px
}

.font-size-sm {
    font-size: $font-size-sm !important;

    @include media-breakpoint-down(md) {
        font-size: $font-size-xs !important;
    }
}

.font-size-sm-alone {
    font-size: $font-size-sm !important;
}

.font-size-d-sm {
    @include media-breakpoint-down(md) {
        font-size: $font-size-sm !important;
    }
}

.font-size-lg {
    font-size: $font-size-lg !important;

    @include media-breakpoint-down(md) {
        font-size: $font-size-lg-md !important;
    }
}

.font-size-xl {
    font-size: $font-size-xl !important;

    @include media-breakpoint-down(md) {
        font-size: $font-size-xl-md !important;
    }
}

.font-size-xxl {
    font-size: $font-size-xxl !important;

    @include media-breakpoint-down(md) {
        font-size: $font-size-xl !important;
    }
}

//
// Font weight
//

.font-weight-semi-bold,.fw-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.font-weight-medium,.fw-medium {
    font-weight: $font-weight-medium !important;
}

//
// Font family alternative
//

.font-primary {
    font-family: $font-family-base !important;
}

.font-lora {
    font-family: 'Lora', serif !important;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.font-fredoka {
    font-family: 'Fredoka One', cursive !important;
}

.font-nunito {
    font-family: 'Nunito', sans-serif !important;
}

.font-awesome {
    font-family: 'Font Awesome 5 Free';
}


//
// Text color
//

.text-w {
    color: $white !important;
}

// Gray text

@each $color, $value in $grays {
  .text-gray-#{$color} {
    color: $value !important;
  }
}

// Text Declaration

.text-underline {
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

// Text opacity

.current-color path {
    fill: currentColor !important;
}

.text-dark-10 {
    color: rgba($dark, .1) !important;
}

.text-dark-70 {
  color: rgba($dark, .7) !important;
}

.text-white-20 {
  color: rgba($white, .2) !important;
}

.text-white-60 {
  color: rgba($white, .6) !important;
}

.text-white-70 {
  color: rgba($white, .7) !important;
}

.text-white-75 {
  color: rgba($white, .75) !important;
}

.text-white-80 {
  color: rgba($white, .8) !important;
}

.text-muted-80 {
  color: rgba($text-muted, .8) !important;
}

.text-white-alone {
    color: $white;
}

.bg-darken {
    .text-dark {
        color: $white !important;
    }

    .text-gray-800 {
        color: rgba($white, .6) !important;

        a {
            &:hover,
            &:focus{
                color: rgba($white, .8) !important;
            }
        }
    }

    a.text-gray-800 {
        &:hover,
        &:focus{
            color: rgba($white, .8) !important;
        }
    }
}

.footer-font-lora {
    .text-dark {
        font-family: 'Lora', serif !important;
        font-weight: $font-weight-bold !important;
    }

    .font-size-sm-alone {
        font-size: 13px !important;
    }
}

.footer-font-nunito {
    .text-dark {
        font-family: 'Fredoka One', cursive !important;
    }
}

.dark-70 {
    .text-gray-800 {
        color: rgba($white, .7) !important;

        a {
            &:hover,
            &:focus{
                color: rgba($white, .88) !important;
            }
        }
    }

    a.text-gray-800 {
        &:hover,
        &:focus{
            color: rgba($white, .88) !important;
        }
    }
}

.white-100 {
    .text-gray-800 {
        color: $white !important;

        a {
            &:hover,
            &:focus{
                color: $white !important;
            }
        }
    }

    a.text-gray-800 {
        &:hover,
        &:focus{
            color: $white !important;
        }
    }
}

.text-white-all {
    color: $white !important;

    &:hover,
    &:focus{
        color: $white !important;
    }
}

.text-hover-white {
    &:hover,
    &:focus{
        color: $white !important;
    }
}


// Letter spacing

.letter-spacing-lg {
  letter-spacing: $letter-spacing-lg !important;
}

// Line Clamp

.line-clamp-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.line-clamp-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.line-clamp-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.text-gradient-1 {
    background: linear-gradient(90deg, $tangerine 0.78%, $gigas 71.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
