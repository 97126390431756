//
// choices.scss
//

.choices::after {
    display: none;
}

// Dropdown menu
.choices .dropdown-menu {
    width: 100%;
}

// Specifying explicitely because Choices.js won't allow you to specify multiple classnames at the same time
.choices .form-control-xs ~ .dropdown-menu,
.choices .custom-select-xs ~ .dropdown-menu {
    width: auto;
    min-width: 100%;
    padding: $dropdown-padding-y-xs $dropdown-padding-x-xs;
}

// Flip Dropdown menu

.is-flipped .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
}

.choices-label {
    position: relative;


    .choices {
        position: static;
        flex-grow: 1;

        .show.dropdown-menu {
            margin-top: 1px;
        }
    }
}
