//
// reboot.scss
// Extended from Bootstrap
//

html, body {
  overflow-x: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
}
