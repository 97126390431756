#wa-btn-wrapper {
  z-index: 999999;
}

@media (min-width: 992px) {
  #wa-btn-wrapper {
    margin-right: '1.5rem';
  }
}



#profiles-container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-items: center;
  background-image: linear-gradient(to bottom, #5a85dd 0%, rgba(78, 99, 215, 0.9) 100%);

  position: absolute;
  min-width: 100%;
  overflow-y: auto;
}

#profiles-container .container{
  position: absolute;
  top: 6rem;
  min-width: 100%;
}

.user-profile {
  min-height: 300px;
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.user-profile .picture {
  display: inline-block;
  height: 110px;
  width: 110px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}

.user-profile .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: rgb(255, 199, 139);
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.user-profile:hover .picture::before {
  height: 100%;
}

.user-profile .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(255, 199, 139);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.user-profile .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.user-profile:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.user-profile .team-content {
  padding: 10px;
}

.user-profile .team-content .name {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: auto;
}

.user-profile .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.user-profile .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgb(255, 199, 139);
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.user-profile:hover .social {
  bottom: 0;
}

.user-profile .social h6 {
  margin: 0.5rem;
  font-size: 0.8rem;
}


.user-profile .social li {
  display: inline-block;
}

.user-profile .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.user-profile .social li a:hover {
  color: #1369ce;
  background-color: #f7f5ec;
}
