body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body.modal-open nav.navbar {
  z-index: 1 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.errorAlert {
  color: red;
}

.errorAlertSmall {
  color: red;
  font-size: 14px;
}


/* Dual List Box */
.react-dual-listbox {
  min-height: 400px;
}

.react-dual-listbox select option {
  padding: 0.35rem 0px;
  font-size: 1rem;
}

.react-dual-listbox .rdl-control-container {
  border-right: 1px solid lightgray;
  padding: 1rem;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background: white;
}

.react-dual-listbox .rdl-searcher-container {
    border-right: 1px solid lightgray;
    padding: 1rem 1rem 0rem;
    border-left: 1px solid lightgray;
    border-top: 1px solid lightgray;
    background: white;
}

.react-dual-listbox .rdl-control-container
.react-dual-listbox select {
  border: none;
}

.rdl-filter-container{
  background: white;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  padding-top: 1rem;
}

.rdl-filter {
  margin: 0rem 1rem;
  width: 94%;
  background: white;
  background-color: #F7F9FB !important;
}

.rdl-control {
  height: 400px !important;
}


/*

.react-dual-listbox-searchform {
  background-color: white;
}

.react-dual-listbox-searchform .input-group {
  background: white;
  border: 1px solid lightgray;
}

.react-dual-listbox-searchform .form-control-sm {
  height: calc(1.388em + 1.125rem + 2px);
}
*/




.nav-tabs .nav-link {
  padding: 0.5rem 1rem !important;
}

.nav-tabs .nav-link.active {
  color: #2F2D51 !important;
}

.nav-tabs .nav-link.disabled {
  cursor: not-allowed !important;
}


/* Tablas */
.table thead > tr > th {
  padding: 0.75rem;
}

.table th, .table td {
  padding: 1.5rem 1rem;
}

.table tbody tr.disabled {
  background: #ebebeb;
  color: darkgray;
}

.table-rounded {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.table {
  /* color: #77838F; */
  color: #1d2125;
}

.fas-action {
  font-size: 1.25rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.fas-action.fa-flag:hover,
.fas-action.fa-user-alt-slash:hover,
.fas-action.fa-window-close:hover {
  color: red !important;
}

.fas-action.fa-flag.active,
.fas-action.fa-user-alt-slash.active {
  color: red !important;
}


/* Loading */
.loading-background {
  z-index: 9999;
}

.navbar{
  z-index: 2;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item {
  white-space: normal;
}


/* Modals */
.modal {
  background: rgba(0, 0, 0, 0.8); 
}
.modal-backdrop {
  display: none;
}

@media (min-width: 576px) {
  .modal-dialog.extended {
      max-width: 750px !important;
  }
}

/* Mobile */
@media (max-width: 750px) {
  .w-25{
    width: 100% !important;
  }
}

@media (min-width: 751px) and (max-width: 1200px)  {
  .w-25{
    width: 50% !important;
  }
}


/* Nuevo Dual List */
.dual-listbox .dual-listbox__container {
  width: 100% !important;
}

.dual-listbox .dual-listbox__title {
  border-top: none !important;
  padding: 0 !important;
}

.dual-listbox .dual-listbox__search {
    background-color: #F7F9FB !important;
}

.dual-listbox .dual-listbox__container > div:not(.dual-listbox__buttons) {
  width: calc( 50% - 50px);
  background: white;
  border: 1px solid lightgray;
  padding: 0.75rem;
}

.dual-listbox .dual-listbox__buttons {
    width: 50px !important;
}

.dual-listbox .dual-listbox__available, 
.dual-listbox .dual-listbox__selected {
  width: 100% !important;
}

.dual-listbox .dual-listbox__button{
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fff;
  color: #333;
}

.dual-listbox__button.hidden {
  display: none !important;
}

.chartjs-size-monitor {
    position: fixed !important;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
  .chartjs-size-monitor {
      position: fixed !important;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .shadow {
    box-shadow: none !important;
  }
  #wrapper #content-wrapper {
    background-color: white !important;
  }
}


.bMgaAx div:first-child {
  white-space: pre-wrap !important;
  text-align: left;
}
