// Global component styles

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

a {
  &:focus {
    outline: none;
  }
}

// Main page wrapper
// Scrolls
#root {
  position: fixed;
  width: 100%;
  height: 100%;
}
#root-scroll {
  overflow-x: scroll;
  width: 100%;
  height: 100%;
}

// fix: modals
body.modal-open {
  position: fixed;
  width: 100%;
  height: 100%;
  #root {
    width: inherit;
    height: inherit;
    position: initial !important;
    #root-scroll {
      overflow-x: initial !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .modal-content{
      /* margin-bottom: 140px; */
    }
  }
}


#wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  #accordionSidebar {
    overflow-x: scroll;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 8rem;
    /* border-right: 1px solid #E7E7EC; */
  }
  #content-wrapper {
    background-color: $gray-100;
    width: 100%;
    overflow-x: scroll;
    #content {
      flex: 1 0 auto;
      padding: 1rem;
      .container-fluid {
        margin-bottom: 6rem;
      }
    }
  }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, .5);
  line-height: 46px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}
