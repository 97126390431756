.green-border-shadow {
	-webkit-box-shadow: 0px 0px 2px 4px #a0d675;
    -moz-box-shadow: 0px 0px 2px 4px #a0d675;
    box-shadow: 0px 0px 2px 4px #a0d675;
}

input.form-control.form-control-height,
select.form-control.form-control-height {
    height: 2rem;
    padding: 0px;
    font-weight: bold;
    margin-left: -4px;
    border: 0px;
    border-color: white;
}

input.form-control.form-control-height:hover,
select.form-control.form-control-height:hover {
    transition: border-color 0.3s linear;
    border: 1px solid #E7E7EC !important;
}
