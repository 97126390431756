.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btn-outline-primary {
    color: #8f9398;
    border-color: #8f9398;
    padding: 0.5rem 2rem !important; 
}

.btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #1eb986 !important;
    border-color: #1eb986  !important;
}

.btn-check:active+.btn-outline-primary, 
.btn-check:checked+.btn-outline-primary, 
.btn-outline-primary.active, 
.btn-outline-primary.dropdown-toggle.show, 
.btn-outline-primary:active {
    color: #FFFFFF;
    background-color: #1BD396  !important;
    border-color: #1BD396 !important;
}

input[type="checkbox" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

