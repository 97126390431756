//
// shadows.scss
// Extended from Bootstrap
//

.shadow-light {
  box-shadow: $box-shadow-light !important;
}

.shadow-light-lg {
  box-shadow: $box-shadow-light-lg !important;
}

.shadow-dark {
  box-shadow: $box-shadow-dark !important;
}

.shadow-dark-lg {
  box-shadow: $box-shadow-dark-lg !important;
}

.shadow-lift {
  box-shadow: $box-shadow-lift !important;
}

.shadow-dark-hover {
    &:hover,
    &:focus {
        box-shadow: $box-shadow-dark !important;
    }
}

.shadow-custom {
    box-shadow: 0px 0px 10px rgba(140, 152, 164, 0.4) !important;
}
