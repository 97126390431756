.nav {
    margin-bottom: 15px;
    text-align: center;
}

.divider {
    background: lightgray;
    height: 1px;
}

.step .label {
    text-align: center;
    margin-top: 4px;
    width: 100%;
}

.step .circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    align-items: center;
    background-color: white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 28px;
    margin: 0 auto;
    color: #77838F;
    border: 1px solid  #77838F;
}

.step.active .circle {
    color: #3280CD !important;
    border: 1px solid #3280CD !important;
}

.step.done .circle {
    background-color: #3280CD !important;
    color: white !important;
    border: 1px solid #3280CD !important;
    cursor: pointer;
}

.step.active .label,
.step.done .label {
    color: #3280CD !important;
}


.step:not(.lastone) .circle:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: lightgray;
    top: calc(0.75em - -3px);
    z-index: -1;
}

.step:not(.lastone).done .circle:after {
    background-color: #21a2d1 !important;

}


.dragdrop-zone {
    border: 2px dashed lightgray;
    min-height: 320px;
    background: white;
    vertical-align: middle;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.dragdrop-zone.highlighted {
    border: 2px dashed lightblue;
    min-height: 320px;
    background: #f4f4f4;
}

.dragdrop-zone > div {
    margin: 0 auto;
}

.dragdrop-zone img.csv-icon {
    width: 50px;
    opacity: 0.75;
}

.table thead > tr > th {
    /* background: #ecf2f7; */
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.react-bootstrap-table .table th > div{
    display: flex;
}

.react-bootstrap-table .table th select.form-control {
    padding: 0px;
    height: auto;
    min-width: 150px;
}

.react-bootstrap-table .table th, 
.react-bootstrap-table .table td {
    min-width: 200px;
    padding: 0.5rem 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.react-bootstrap-table .table 
td.react-bootstrap-table-editing-cell {
    padding: 0px;
    align-items: center;
    text-align: center;
}

.react-bootstrap-table .table 
td.react-bootstrap-table-editing-cell select.form-control {
    border-radius: 0px;
    padding: 0.70rem 1.25rem;
    height: 100%;
    border: none;
    box-shadow: inset 0 0 0 1px #4299e1;
}

.react-bootstrap-table .table 
td.react-bootstrap-table-editing-cell input.form-control {
    border-radius: 0px;
    padding: 0.75rem 1.50rem;
    height: 100%;
    border: none;
    box-shadow: inset 0 0 0 1px #4299e1;
}

.react-bootstrap-table .table 
td.react-bootstrap-table-editing-cell input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin: 8px auto;
}



.table-match th, 
.table-match td {
    padding: 1rem 1rem;
}


.table-match select.form-control {
    padding: 0.25rem;
    height: auto;
    color: #54585c;
}

.table-match .icons-match {
    color: #6e9ca6; /*#4A8F9F*/;
    font-size: 1.25rem;
    float: right;
}

.table-match .icon-done {
    color: #8ba1a6;
    font-size: 1.25rem;
    margin-top: 4px;
}

.table-match .icon-done.icon-ready {
    color: #3280CD !important
}









.rsw-wrapper {
     min-height: 400px !important;
}
 @keyframes stripes {
     from {
         background-position: 1rem 0;
    }
     to {
         background-position: 0 0;
    }
}
 @keyframes rainbow {
     0% {
         background-color: var(--red);
    }
     20% {
         background-color: var(--orange);
    }
     40% {
         background-color: var(--yellow);
    }
     60% {
         background-color: var(--green);
    }
     80% {
         background-color: var(--blue);
    }
     100% {
         background-color: var(--indigo);
    }
}
 @keyframes grow {
     from {
         width: 0%;
    }
     to {
         width: 100%;
    }
}
 .progress-wrapper {
     padding-top: 25%;
}
 .progress {
     height: 5px;
     overflow: hidden;
}
 .progress-bar {
     height: 5px;
     width: 100%;
}
 .loaded .progress-bar {
     animation: grow 3s ease, stripes 1s linear infinite, rainbow 3s ease infinite;
}









/* School Screen Tabs */
@media (max-width: 992px) {

    .nav-tabs.tabs-admin {
        margin: 0px !important;
    }

    .nav-tabs.tabs-admin .nav-link.active {
        background: white !important;
        border-radius: 10px !important;

    }

}




.nav-tabs.tabs-admin {
    background-color: #4e63d7;
    background-image: linear-gradient(to bottom, rgba(78, 99, 215, 0.9) 0%, #5a85dd 100%);
    padding: 2rem 1rem 0rem;
    margin: -1rem calc(-1rem - 15px);
}

.nav-tabs.tabs-admin .nav-link.active {
    background: url(../img/icons/ic_tab3.png) 50% center no-repeat;
    background-size: auto;
    background-position: 50% 43%;
    color: #2f2d51 !important;
    font-weight: 500;
    border: none;
}

.nav-tabs.tabs-admin .nav-link.active .tab-icon {
    color: #ff7b42 !important;
}

.nav-tabs.tabs-admin .nav-link {
    padding: 0.75rem 5rem !important;
    margin-bottom: -3px;
    font-weight: 500;
    color: white;
}

.nav-tabs.tabs-admin .nav-link:hover {
    border: none !important;
}

#schoolTabsContent{
    padding: 4rem 0rem 0rem;
}



#groups-pills-tab.nav-pills .nav-link {
    background: #ecedf3;
    margin: 6px 0px;
    color: #2f2d51;
}

#groups-pills-tab.nav-pills .nav-link.active {
    background: #f8f9fc;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}




/* Listas */

/* ===== Career ===== */
.career-form {
  background-color: #4e63d7;
  border-radius: 5px;
  padding: 0 16px;
}

.career-form .form-control {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  padding: 12px 15px;
  color: #fff;
}

.career-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.career-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.career-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.career-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.career-form .custom-select {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  padding: 12px 15px;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  text-align: left;
  background-image: none;
}

.career-form .custom-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.career-form .select-container {
  position: relative;
}

.career-form .select-container:before {
  position: absolute;
  right: 15px;
  top: calc(50% - 14px);
  font-size: 18px;
  color: #ffffff;
  content: "\F107";
  font-family: 'Font Awesome 5 Free';
}

.filter-result .job-box {
background:#fff;
  -webkit-box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
          box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
  border-radius: 10px;
  padding: 10px 35px;
}

ul {
  list-style: none; 
}

.list-disk li {
  list-style: none;
  margin-bottom: 12px;
}

.list-disk li:last-child {
  margin-bottom: 0;
}

.job-box .img-holder {
  height: 65px;
  width: 65px;
  background-color: #4e63d7;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(78, 99, 215, 0.9)), to(#5a85dd));
  background-image: linear-gradient(to right, rgba(78, 99, 215, 0.9) 0%, #5a85dd 100%);
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 65px;
}

.career-title {
  background-color: #4e63d7;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(78, 99, 215, 0.9)), to(#5a85dd));
  background-image: linear-gradient(to right, rgba(78, 99, 215, 0.9) 0%, #5a85dd 100%);
}

.job-overview {
  -webkit-box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
          box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
  border-radius: 10px;
}

@media (min-width: 992px) {
  .job-overview {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
  }
}

.job-overview .job-detail ul {
  margin-bottom: 28px;
}

.job-overview .job-detail ul li {
  opacity: 0.75;
  font-weight: 600;
  margin-bottom: 15px;
}

.job-overview .job-detail ul li i {
  font-size: 20px;
  position: relative;
  top: 1px;
}

.job-overview .overview-bottom,
.job-overview .overview-top {
  padding: 35px;
}

.job-content ul li {
  font-weight: 600;
  opacity: 0.75;
  border-bottom: 1px solid #ccc;
  padding: 10px 5px;
}

@media (min-width: 768px) {
  .job-content ul li {
    border-bottom: 0;
    padding: 0;
  }
}

.job-content ul li i {
  font-size: 20px;
  position: relative;
  top: 1px;
}

.mb-30 {
    margin-bottom: 30px;
}

.btn-primary{
    background-color: #4e63d7;
    background-image: linear-gradient(to right, rgba(78, 99, 215, 0.9) 0%, #5a85dd 100%);
    color: white;
}

.btn-link-add{
    font-weight: 400;
    color: #090761;
    text-decoration: none;
}

.btn-link-add:hover{
    background-color: #4e63d7;
    background-image: linear-gradient(to right, rgba(78, 99, 215, 0.9) 0%, #5a85dd 100%);
    color: white;
}


