//
// buttons.scss
// Extended from Bootstrap
//

@mixin btn-variant-soft($bg, $color) {
  background-color: $bg;
  color: $color;

  &:hover,
  &:focus {
    background-color: fade-in($bg, .05);
    color: $color;
  }

  &.active {
    background-color: fade-in($bg, .05);
    color: $color;
  }
}
