//
// background.scss
// Extended from Bootstrap
//

.bg-cover {
  background: no-repeat center center / cover;
}

.bg-cover-left {
    background: no-repeat left center / cover;
}


// Between

.bg-between {

  @include media-breakpoint-down(md) {
    background-image: none !important;
  }

  @include media-breakpoint-up(md) {
    background-repeat: no-repeat;
    background-position: left center,  right center;
    background-size: auto 70%;
  }
}


// Gradients

.bg-gradient-light {
  background-image: linear-gradient(to bottom,  $light 0%,  $white 100%);
}

.bg-gradient-1 {
    background: linear-gradient(90deg,  #FFA35A 0.26%,  #FF2574 100.08%) !important;
}

.bg-gradient-2 {
    background: linear-gradient(284.28deg, #311343 26.18%, #2C146C 100%);
}


// Colors

.bg-gray-100 {
  background-color: $gray-100 !important;
}

.bg-gray-300 {
  background-color: $gray-300 !important;
}

.bg-gray-800 {
  background-color: $gray-800 !important;
}

.bg-gray-900 {
  background-color: $gray-900 !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-white-10 {
    background-color: rgba($white, .1);
}

.bg-orange-40 {
    background-color: rgba($orange, .4);
}

.bg-white-hover-10 {
    &:hover,
    &:focus {
        background-color: rgba($white, .1);
    }
}

.bg-white-all {
    background-color: $white;

    &:hover,
    &:focus {
        background-color: $white;
    }
}

.bg-white-hover {
    &:hover,
    &:focus {
        background-color: $white;
    }
}
