// Global styles for both custom sidebar and topbar compoments

.sidebar,
.topbar {
  .nav-item {
    // Counter for nav links and nav link image sizing
    .nav-link {
      position: relative;
      .badge-counter {
        position: absolute;
        transform: scale(0.7);
        transform-origin: top right;
        right: -0.15rem;
        margin-top: -0.75rem;
      }
      .img-profile {
        height: 4rem;
        width: 4rem;
      }
      .btn-change-profile {
        border-radius: 5px;
        border: 1px solid gray;
        padding: 1px 10px;
        text-align: left !important;
        float: right;
        &:hover {
          background-image: linear-gradient(90deg,rgba(78,99,215,.9) 0,#5a85dd);
          color: #fff;
          border: 1px solid transparent;
        }
      }
    }
  }
}

.topbar {
  #logo {
    width: 50%;
    /* height: 100%; */
    padding: 1rem;
    min-width: 250px;
  }
}

@include media-breakpoint-down(sm) {
  .topbar {
    #logo {
      display: none !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .topbar {
    #logo {
      width: 19rem;
    }
  }
}
